import React from 'react'
import styled from 'styled-components'
import { ReactTypeformEmbed } from 'react-typeform-embed'

const GetInTouch = () => {
  return (
    <Section>
      <ReactTypeformEmbed url='https://askphill1.typeform.com/to/x2aEpZ' />
    </Section>
  )
}

const Section = styled.div`
  min-height: 100vh;
  .ReactTypeformEmbed {
    height: 100vh !important;
  }
`

export default GetInTouch
